<template lang="pug">
  .row.global-settings
    .col-md-3
      tabs-list
    .col-md-9
      router-view
</template>

<script>
import { mapActions } from 'vuex'
import TabsList from './common/TabsList'

export default {
  name: 'Preferences',

  components: {
    TabsList
  },

  mounted () {
    this.loadLanguages()
  },

  methods: {
    ...mapActions('app', ['loadLanguages'])
  }

}
</script>

<style lang="scss">
.global-settings {
  :not(.accessTokens).v-card .v-card__content {
    padding: 32px 24px 24px 24px !important;
  }

  .v-card p.subtitle {
    margin-top: 16px !important;
    margin-bottom: 32px !important;
  }
}
</style>
