<template lang="pug">
  div.tablist
    v-scrollable
      .desktop-nav
        v-card(transparent)
          v-card-head
            v-card-head-label
              h2.title-2.mb-3 {{ 'ui.labels.global_settings' | translate }}
              p {{ 'infotext.settings_description' | translate }}
          v-card-content
              v-tab-item(
                v-for="item in tabs"
                vertical
                :key="item.id"
                :active="isActive(item)"
                :path="item.path")
                | {{ item.title | translate}}

    .mobile-nav
      ui-dropdown-nav.mb-4(
        :title="'ui.labels.role' | translate"
        :options="tabs")

</template>

<script>
import UiDropdownNav from '@/components/ui/DropdownNav'

export default {
  name: 'TabsList',

  components: {
    UiDropdownNav
  },

  data: () => ({
    tabs: [
      { id: 5, title: 'pages.profile.general', path: '/settings/global-settings/general-settings' },
      { id: 0, title: 'pages.settings.company', path: '/settings/global-settings/company' },
      { id: 1, title: 'pages.settings.data_and_sync', path: '/settings/global-settings/data' },
      { id: 2, title: 'pages.settings.user', path: '/settings/global-settings/user' },
      { id: 3, title: 'pages.settings.personal_access_token', path: '/settings/global-settings/access-token' },
      { id: 4, title: 'pages.settings.email_settings', path: '/settings/global-settings/email-settings' }
    ]
  }),

  methods: {
    isActive (item) {
      return this.$route.path === item.path
    }
  }
}
</script>

<style lang="scss">
// TODO: Check if all card in design would look the same after design will be end up
.tablist {
  .v-card .v-card__head {
    padding-top: 0!important;
  }

  .title-2 {
    font-weight: 700 !important;
    font-size: 20px !important;
  }
  p {
    font-size: 18px;
  }
}
</style>
